import { valueExist } from "@/utils/index";
import { isElement } from "underscore";
export class LeadgreaseBirthday{

    constructor(el){
        this.el = isElement(el) ? el : document.querySelector(el);

        this.name = "LeadgreaseBirthday";
        this.days = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];// index => month [0-11]
        this.months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
        this.elements = {
            month: this.initSelectMonth(),
            day: this.initSelectDay(31),
            year: this.initSelectYear(),
        }

    }

    getSelectDay(){
        return this.el.querySelector('[data-slg-type="C-SELECT-DAY"]') || this.el.querySelector("[data-type='birthday-day']");
    }
    getSelectMonth(){
        return this.el.querySelector("[data-slg-type='C-SELECT-MONTH']") || this.el.querySelector("[data-type='birthday-month']");
    }

    getSelectYear(){
        return this.el.querySelector("[data-slg-type='C-SELECT-YEAR']") || this.el.querySelector("[data-type='birthday-year']");
    }

    proxyData(data){
        delete data[this.elements.day.name];
        delete data[this.elements.month.name];
        delete data[this.elements.year.name];

        let month = parseInt(this.elements.month.value) + 1;
        month = (month < 10)? `0${month}`: month;

        let day = parseInt(this.elements.day.value);
        day = (day < 10)? `0${day}`: day;
        data.birth_date = this.elements.year.value + "-" + month + "-" + day;

        return data;
    }
    initSelectMonth(){
        let selectDay = this.getSelectDay();
        let selectMonth = this.getSelectMonth();
        if (selectDay) {
            selectMonth.innerHTML = '';
            selectMonth.onchange = (changeMonth) => {
                let option = changeMonth.target.value
                if (!isNaN(parseInt(option)))
                    this.initSelectDay(this.days[option]);
            };
            for (let month = 0; month < this.months.length; month++) {
                let MonthOpt = document.createElement("option");
                MonthOpt.value = month;
                MonthOpt.text = this.months[month];
                selectMonth.add(MonthOpt, null);
            }
        }

        return selectMonth;
    }


    initSelectDay(days = 31){
        let birthDaySelect = this.getSelectDay();
        let value = birthDaySelect.value;
        birthDaySelect.innerHTML = "";
        for (let day = 1; day < days + 1; day++) {
            let DayOpt = document.createElement("option");
            DayOpt.value = day;
            DayOpt.text = day;
            birthDaySelect.add(DayOpt, null);
        }
        if(value && valueExist(value,birthDaySelect))
            birthDaySelect.value = value;
        return birthDaySelect;
    }

    initSelectYear(){
        let toYear = parseInt((new Date().getFullYear())) - 17;
        let fromYear = toYear - 80;
    
        let selectYear = this.getSelectYear();
        selectYear.innerHTML = '';
        for (let year = toYear; year > fromYear; year--) {
            let MonthOpt = document.createElement("option");
            MonthOpt.value = year;
            MonthOpt.text = year
            selectYear.add(MonthOpt, null);
        }

        return selectYear;
    }

}