export default class LeadgreaseAuthentication {
    constructor(config = {}) {
        this.mapHeaders = {
            "apiKey": "x-api-key",
            "appId": "x-app-id",
            "formKey": "x-form-token"
        };
        this.credentials = {};
        for (const key in config) {
            const element = config[key];
            if (element && this.mapHeaders[key]) {
                this.credentials = Object.assign(Object.assign({}, this.credentials), { [this.mapHeaders[key]]: element });
            }
        }
        this.accountCode = config.accountCode;
    }
    getCredentials() {
        return this.credentials;
    }
    getAccountCode() {
        return this.accountCode;
    }
    setAccountCode(accountCode) {
        this.accountCode = accountCode;
        return this;
    }
}
