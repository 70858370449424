/****
 *  Usage
 *  var lgConfig= {
 *      appId: "Account ID",
 *      apiKey: "Provider Key",
 *      <optional> form: "Form Key",
 *
 *  }
 * var lgCustom= {
 *     onSuccess: function(){
 *          // Action
 *     }
 * }
 * 
 * var stepsConfig = { 
 *      "countSteps": "Total steps",
 *      "currentStep": " Current step" 
 * };
 * @param Config
 * @returns {{formKey: (*|string)}}
 */

import { Loader } from "@/modules/loader/index";
import { LeadgreaseSingleForm } from "@/modules/form/singleForm";
import { LeadgreaseAuthentication } from "@/modules/authentication/index";
import { LeadgreaseSponsor } from "@/modules/sponsor/index";
import { LeadgreaseLayerForm } from "@/modules/form/layerForm";
import { LeadgreaseFormSwiper } from "@/modules/form/formSwiper";
import { LeadgreaseApiCapture } from "@/modules/api/index";
import { LeadgreaseEndpointsV2 } from "@/modules/api/endpoints/index";
import { LeadgreaseEndpointsV1 } from "@/modules/api/endpoints/index";
import 'bootstrap';
let apiVersion = document.currentScript.getAttribute("api-version");
let autoLoad = ((document.currentScript.hasAttribute("auto-load"))
    && document.currentScript.getAttribute("auto-load") === "false") ? false : true;
let appId = document.currentScript.getAttribute("app-id");
let isDev = document.currentScript.hasAttribute("is-dev") ? true : false;

window.leadgrease = {
    appId: appId,
    isDev: isDev,
    Forms: [],
    Form: null, // Set main form on autoload
    Loader: new Loader(),
    EndPoints: (apiVersion != 2) ? new LeadgreaseEndpointsV1() : new LeadgreaseEndpointsV2(),

    getAppId() {
        return this.appId;
    },
    setAppId(appId) {
        this.appId = appId;
    },

    initForm(el, config) {
        let lgForm;
        if (el.getAttribute('data-slg-type') == "C-FORM-LAYERS") {
            lgForm = new LeadgreaseLayerForm(el, config, this);
        } else if ((el.getAttribute('data-slg-type') == "C-FORM-SWIPER")) {
            lgForm = new LeadgreaseFormSwiper(el, config, this);
        } else {
            lgForm = new LeadgreaseSingleForm(el, config, this);
        }
        lgForm.init();
        this.Forms.push(lgForm);
        return lgForm;
    },

    init({ isDev, appId } = {}) {
        this.Loader.show();
        if (isDev != undefined && isDev != null) {
            this.isDev = isDev;
        }

        if (appId != undefined && appId != null) {
            this.appId = appId;
        }

        this.autocomplete();

        this.Loader.hide();
    },

    autocomplete() {
        const params = Object.fromEntries(new URLSearchParams(location.search));
        let textAutocomplete = document.querySelectorAll("[data-lg-autocomplete]");
        Array.from(textAutocomplete).forEach((element) => {
            for (const key in params) {
                element.innerHTML = element.innerHTML.replace(`{{${key}}}`, params[key]);
            }
        })
    }
};

if (autoLoad) {
    console.log("Leadgrease: autoload active");
    let config = {
        lgConfig
    }

    if (appId != undefined && appId != null) {
        config.lgConfig = {
            ...config.lgConfig,
            appId
        }
    }
    if (typeof lgCustom !== "undefined") {
        config = {
            ...config,
            lgCustom,
        }
    }
    if (typeof lgPixels !== "undefined") {
        config = {
            ...config,
            lgPixels
        }
    }
    if (typeof stepsConfig !== "undefined") {
        config = {
            ...config,
            stepsConfig
        }
    }
    leadgrease.init({
        isDev: config.lgConfig.isDev,
        appId: config.lgConfig.appId,
    });
    let form = document.querySelector('form');
    if (form)
        leadgrease.Form = leadgrease.initForm(form, {
            ...config,
            apiKey: config.lgConfig.apiKey,
            formKey: config.lgConfig.formKey,
        });


}




global.LeadgreaseEndpointsV2 = LeadgreaseEndpointsV2;
global.LeadgreaseEndpointsV1 = LeadgreaseEndpointsV1;