import { isElement, max } from "underscore";
import { LeadgreaseSelect } from "./components/select";
import { API_CAPTURE, API_CAPTURE_DEV } from "@/config/config";
import getPopulateQuery from "@/utils/populateQuery";
import getParameterByName from "@/utils/getParameterByName";
import { LeadgreaseSponsor } from "@/modules/sponsor/index";
import { LeadgreaseBirthday } from "./components/bithday";
import { LeadgreaseQuestion } from "./components/question";
import { LeadgreaseAuthentication } from "@/modules/authentication/index";
import { LeadgreaseApiCapture } from "@/modules/api/index";
import { LeadgreaseConditional } from "@/modules/form/components/index";
import { fetchIP, getFieldsFromEl } from "@/utils/index";
export class LeadgreaseSingleForm {
    constructor(el, { apiKey, formKey, lgPixels, lgCustom, stepsConfig, attributes, sponsor = "#sponsor", initEventSubmit = true } = {}, lgInstance) {
        this.el = isElement(el) ? el : document.querySelector(el);
        if (!this.el) {
            console.warn("Form El is not defined");
        }
        this.elements = [];
        this.conditions = [];
        this.hooks = {};
        this.data = {};
        let queryParams = new URLSearchParams(window.location.search);
        for (const [key, value] of queryParams.entries()) {
            this.data = {
                ...this.data,
                [key]: value
            }
        }

        apiKey = (el.hasAttribute('data-lg-api-key')) ? el.getAttribute('data-lg-api-key') : apiKey;
        apiKey = (el.querySelector('[name="api-key"]')) ? el.querySelector('[name="api-key"]').value : apiKey;

        formKey = (el.hasAttribute('data-lg-form-key')) ? el.getAttribute('data-lg-form-key') : formKey;
        formKey = (el.querySelector('[name="form-key"]')) ? el.querySelector('[name="form-key"]').value : formKey;

        this.authentication = new LeadgreaseAuthentication({
            appId: lgInstance.appId,
            apiKey: apiKey,
            formKey: formKey,
        });
        this.apiCapture = new LeadgreaseApiCapture(this.authentication, lgInstance.isDev, lgInstance.EndPoints);

        this.lgCustom = lgCustom;
        this.lgPixels = lgPixels;
        this.stepsConfig = stepsConfig;


        this.attributes = attributes;
        this.setAttributes(this.attributes);

        this.loader = lgInstance.Loader;

        sponsor = isElement(sponsor) ? sponsor : document.querySelector(sponsor);

        if (lgInstance.appId && apiKey && formKey && sponsor)
            this.sponsor = new LeadgreaseSponsor(sponsor, this.apiCapture);
        else
            this.sponsor = null;

        this.lgInstance = lgInstance;
        this.config = {
            initEventSubmit
        };
        //this.init({ initEventSubmit });

    }

    async init() {
        this.checkChangeForm();
        this.initQuestions();
        this.initBirthday();
        this.initSelect();
        this.initConditional();
        this.resetValidation();
        this.initControlInputs();
        this.autoPopulateQuery();
        this.autoSubmit();
        this.checkIfGoToNextStep();
        if (this.config.initEventSubmit) this.initEventSubmit();
    }

    initFields() {

    }

    checkIfGoToNextStep() {
        this.loader.show();
        let allConditionsValid = this.validateConditions().filter((condition) => condition == true);
        let fieldsNotInConditionalContainer = this.checkFieldsNotInConditionalContainer();
        if (allConditionsValid.length == 0 && fieldsNotInConditionalContainer.length == 0) {
            this.nextStep((parseInt(this.stepsConfig.currentStep) + 1), getParameterByName("leadId"));
        }else{
            this.loader.hide();
        }
    }

    checkFieldsNotInConditionalContainer() {
        let fields = getFieldsFromEl(this.el);
        return fields.filter((field) => {
            for (let i = 0; i < this.conditions.length; i++) {
                const condition = this.conditions[i];
                let find = condition.getFields().find((cf) => {
                    return cf.id == field.id;
                })
                if (find) return false;
            }
            return true;

        });
    }

    checkChangeForm() {
        let fields = this.getFieldsFromForm(this.el);
        for (let i = 0; i < fields.length; i++) {
            fields[i].addEventListener('change', (e) => {
                this.saveDataForm(fields[i]);
            });
        }
    }
    saveDataForm(elementChange) {
        this.data[elementChange.name] = elementChange.value;
        this.validateConditions();
    }

    on(hookEvent, callback) {
        this.hooks = {
            ...this.hooks,
            [hookEvent]: callback
        }
    }

    async exec(hookEvent, data) {
        for (const key in this.hooks) {

            if (hookEvent === key) {
                return await this.hooks[hookEvent](data);
            }

        }
        return data;
    }

    setAttributes(attributes) {
        for (const key in attributes) {
            const attr = attributes[key];

            this.el.setAttribute(key, attr);

        }
    }

    checkValidateField(field) {
        if (field.hasAttribute("data-lg-no-validate")) {
            return false;
        }
        if (field.type === "checkbox" && field.hasAttribute('required')) {
            if (field.checked === false) {
                field.parentNode.classList.add('bg-warning');
                field.focus();
                return true;
            } else {
                field.classList.remove('is-invalid');
            }
        } else if (field.type === "number" && field.getAttribute('pattern')) {
            var text = new RegExp(field.getAttribute('pattern'));

            if (!text.test(field.value)) {
                field.classList.add('is-invalid');
                field.focus();
            } else {
                field.classList.remove('is-invalid');
            }
            return true;
        }

        if (field.hasAttribute('data-slg-validate-pattern')) {
            var text = new RegExp(field.getAttribute('data-slg-validate-pattern'));

            if (!text.test(field.value)) {
                field.classList.add('is-invalid');
                field.focus();
                return true;
            } else {
                field.classList.remove('is-invalid');
                return false;
            }

        }
        if (field.tagName == 'SELECT' && (field.value == "" && field.hasAttribute('required'))) {
            field.classList.add('is-invalid');
            return true;
        }
        if (field.checkValidity() === false) {
            field.classList.add('is-invalid');
            field.focus();
            return true;
        } else {
            field.classList.remove('is-invalid');
            return false;
        }
    }
    /**
     * 
     * @method checkValidateForm
     * @param {*} form DOM element with inputs to validate 
     * @returns Array with elements invalids
     * 
     *  @description Find elements invalid and add class "in-valid"
     */

    checkValidateForm(form) {
        let fields = this.getFieldsFromForm(form);
        return fields.filter(this.checkValidateField);
    }

    resetValidation(el) {
        let fields = this.getFieldsFromForm(el);
        fields.forEach(field => {
            field.classList.remove('is-invalid');
        });
    }

    getFieldsFromForm(el) {
        el = (el) ? el : this.el;
        let inputs = Array.from(el.querySelectorAll('input'));
        let selects = Array.from(el.querySelectorAll('select'));
        let fields = inputs.concat(selects);
        return fields;

    }

    getArrayFromForm() {
        let formHasJson = {};
        for (var formFields in this.el.elements) {
            if (this.el.elements[formFields].name && this.el.elements[formFields].name !== "") {
                let element = this.el.elements[formFields];
                let type = element.type;
                if (type == "checkbox") {
                    formHasJson[element.name] = element.checked;
                }
                else if (type == "radio") {
                    if (!(element.name in formHasJson) && element.checked && element.value != "null") {
                        formHasJson[element.name] = element.value;
                    }
                } else if (type == 'select-one') {
                    if (element.value && element.value != "")
                        formHasJson[element.name] = element.value;
                } else {
                    formHasJson[element.name] = element.value;
                }
            }
        }
        return formHasJson;
    }

    async getData() {
        let data = this.getArrayFromForm();

        for (let i = 0; i < this.elements.length; i++) {
            const element = this.elements[i];
            if (element.name === "LeadgreaseBirthday") {
                data = element.proxyData(data);
            }
        }

        if (this.sponsor) {
            data = this.sponsor.proxyData(data);
        }

        data = this.proxyOrigin(data);
        data = this.proxyLeadId(data);
        data = this.proxyClickId(data);
        data = this.proxyOfferId(data);

        // Clean default data
        delete data["api-key"];
        delete data["form-key"];


        let defaultData;


        if (!data.lead_id) {
            defaultData = await this.getDefaultData();
            data = {
                ...data,
                ...defaultData
            }
        }


        return data;

    }

    async getDefaultData() {

        let responseIP = await fetchIP();
        let url = window.location.href;

        return {
            ip: responseIP.ip,
            url
        }
    }

    proxyOrigin(data) {
        let query_params = new URLSearchParams(window.location.search);

        if (query_params.get('origin')) {
            data.origin = query_params.get('origin');
        }

        return data;
    }
    proxyLeadId(data) {
        let query_params = new URLSearchParams(window.location.search);

        if (query_params.get('leadId')) {
            data.lead_id = query_params.get('leadId');
        }

        return data;
    }

    proxyClickId(data) {
        let queryParams = new URLSearchParams(window.location.search);

        if (queryParams.get('click_id')) {
            data.click_id = queryParams.get('click_id');
        }

        return data;
    }

    proxyOfferId(data) {
        let queryParams = new URLSearchParams(window.location.search);

        if (queryParams.get('offer_id')) {
            data.offer_id = queryParams.get('offer_id');
        }

        return data;
    }


    initSelect() {
        let elements = this.el.querySelectorAll('select');
        for (let i = 0; i < elements.length; i++) {
            this.elements.push(new LeadgreaseSelect(elements[i]));
        }
    }

    initBirthday() {
        let elements = this.el.querySelectorAll("[data-type='birth']") || this.el.querySelectorAll('[data-slg-type="C-BIRTHDAY"]');
        for (let i = 0; i < elements.length; i++) {
            this.elements.push(new LeadgreaseBirthday(elements[i]));
        }
    }

    initQuestions() {
        let questions = document.querySelectorAll('[data-slg-type="C-QUESTION-OPTIONS"]');
        for (let i = 0; i < questions.length; i++) {
            this.elements.push(new LeadgreaseQuestion(questions[i]));
        }
    }

    initConditional() {
        let conditions = document.querySelectorAll('[data-lg-type="C-CONTAINER-CONDITIONAL"]');
        for (let i = 0; i < conditions.length; i++) {
            this.conditions.push(new LeadgreaseConditional(conditions[i], this));
        }
        this.validateConditions();
    }

    validateConditions() {
        return this.conditions.map((condition) => {
            return condition.validate();
        });
    }

    async sendData() {

        let dataForm = await this.getData();

        try {

            dataForm = await this.exec('form:send-data:start', { ...dataForm });
            var response;
            if (dataForm.lead_id) {
                response = await this.apiCapture.updateLead(dataForm);

            } else {
                response = await this.apiCapture.captureLead(dataForm);
            }

            if (!response.success) {
                throw response;
            }

            this.execDefaultPixel(response);

            if (typeof this.lgCustom !== "undefined") {
                if (typeof this.lgCustom.onResponse !== "undefined") {
                    window.setTimeout(function () { this.lgCustom.onResponse(response); }, 600);
                }
                if (typeof this.lgCustom.onCustomResponse !== "undefined") {
                    window.setTimeout(function () { this.lgCustom.onCustomResponse(dataMain); }, 600);
                }
            }

            let dataQuestion = this.getQuestions();
            if (dataQuestion.answers.length > 0)
                await this.apiCapture.captureResponse(dataQuestion);

            await this.exec('form:submit:end', { ...response });

            let cModalLockRecord = document.querySelector('[data-slg-type="C-MODAL-LOCK-RECORD"]');
            if (cModalLockRecord) {
                let matchResponse = cModalLockRecord.getAttribute("data-slg-match-response");
                if (matchResponse && response.status.includes(matchResponse)) {
                    let cModalLockRecord = new bootstrap.Modal(document.getElementById('C-MODAL-LOCK-RECORD'), { backdrop: 'static', keyboard: false });
                    cModalLockRecord.show()
                    return false;
                }
            }



            /** Solo cuando tenemos Steps Habilitados **/
            if (typeof (this.stepsConfig) !== "undefined") {
                this.nextStep((parseInt(this.stepsConfig.currentStep) + 1), response.leadId);
            }
        } catch (error) {
            console.error(error);
            this.exec('form:submit:error', error);
        }
    }

    getQuestions() {
        let data = {
            "answers": [],
            "step_id": getParameterByName("stepId"),
            "leadId": getParameterByName("leadId")
        };
        for (let i = 0; i < this.elements.length; i++) {
            const element = this.elements[i];
            if (element.name === "LeadgreaseQuestion") {
                let answers = element.getData();
                data["answers"] = answers;
            }
        }
        return data;
    }

    async sendQuestionResponse() {
        let dataForm = this.getQuestions();
        if (dataForm.answers.length > 0)
            await this.apiCapture.captureResponse(dataForm);
        if (typeof (this.stepsConfig) !== "undefined") {
            this.nextStep(parseInt(parseInt(this.stepsConfig.currentStep) + 1), getParameterByName("leadId"));
        }
    }



    nextStep(stepId, leadId) {
        // let query = "?step" + stepId + ".html?leadId=" + leadId + "&stepId=" + stepId;

        if (!stepId || !leadId) return;

        let urlNextStep = "step" + stepId + ".html";
        let query = "";
        let currentDataQuery = getPopulateQuery();
        let filedsFollowInQuery = this.getFieldsFollowInQuery();

        let queryData = {
            ...currentDataQuery,
            ...filedsFollowInQuery,
            stepId,
            leadId
        };

        for (const key in queryData) {
            if (Object.hasOwnProperty.call(queryData, key)) {
                const element = queryData[key];
                query += key + "=" + element + "&";

            }
        }

        query = query.slice(0, -1);

        window.setTimeout(function () {
            location.href = urlNextStep + "?" + query;
        }, 600);
    }

    /**
     * @description Find all elements with attribute follow-in-query and build a json with values
     * @returns Json
     */

    getFieldsFollowInQuery() {

        /* Only support simple fields */
        let fields = this.el.querySelectorAll('[data-slg-follow-in-query]');
        let newFields = this.el.querySelectorAll('[data-lg-follow-in-query]');

        let response = {};
        for (let i = 0; i < fields.length; i++) {
            const value = fields[i].value;
            const name = fields[i].name;
            if (name && value)
                response = {
                    ...response,
                    [name]: value
                }
        }

        for (let i = 0; i < newFields.length; i++) {
            const newField = newFields[i];

            if (newField.hasAttribute("data-slg-type") && newField.getAttribute("data-slg-type") == "C-BIRTHDAY") {
                let elementBirthday = this.elements.find((element) => {
                    return element.name == "LeadgreaseBirthday"
                });
                let data = this.getArrayFromForm();
                response = {
                    ...response,
                    "birth_date": elementBirthday.proxyData(data)["birth_date"]
                }
            } else if (newField.name && newField.value) {
                let value;
                if (newField.name && newField.name !== "") {
                    if (newField.type == "checkbox") {
                        value = newField.checked;
                    }
                    else if (newField.type == "radio") {
                        if (newField.checked) {
                            value = newField.value;
                        }
                    } else if (newField.type == 'select-one') {
                        if (newField.value && newField.value != "")
                            value = newField.value;
                    } else {
                        value = newField.value;
                    }
                }
                if (value) {
                    response = {
                        ...response,
                        [newField.name]: value
                    }
                }

            }
        }

        return response;
    }

    autoPopulateQuery() {
        let query = getPopulateQuery();
        for (const key in query) {
            try {
                const element = query[key];
                let fields = this.el.querySelectorAll(`[name="${key}"]`);
                for (let i = 0; i < fields.length; i++) {
                    const field = fields[i];
                    const autocomplete = field.getAttribute('data-lg-autocomplete');
                    if (field && autocomplete !== 'off')
                        field.value = element;
                }

            } catch (error) {
                console.warn(error);
            }

        }
    }

    disable() {
        /* When send data disable button sumbit */
        let button = this.el.querySelector("[type=submit]");
        if (button)
            button.classList.add("disabled");
    }

    active() {
        /* When send data disable button sumbit */
        let button = this.el.querySelector("[type=submit]");
        if (button)
            button.classList.remove("disabled");
    }
    autoSubmit() {
        var form = this.el;
        let fields = this.getFieldsFromForm();
        let findSelectableFields = fields.filter(field => field.tagName == "SELECT" || (field.tagName == "INPUT" && field.type == "radio"));

        if (fields.length === findSelectableFields.length) {
            for (let i = 0; i < fields.length; i++) {
                const field = fields[i];
                field.addEventListener("change", async (e) => {
                    let validation = this.checkValidateForm(form);
                    if (validation.length == 0) {
                        this.submit();
                    } else {
                        this.resetValidation();
                    }
                });
            }
        }
    }

    async submit(event = null) {

        try {

            if (event) event.preventDefault();
            this.disable();
            this.loader.show();
            this.validation = this.checkValidateForm();
            if (this.validation.length === 0) {
                if (this.stepsConfig && parseInt(this.stepsConfig.currentStep) > 0) {
                    await this.sendData();
                    // await this.sendQuestionResponse();
                } else {
                    await this.sendData();
                }
            }else{
                this.loader.hide();
                this.active();
            }

        } catch (error) {
            console.error(error);
            this.loader.hide();
            this.active();
        }
    }
    initEventSubmit() {
        let btn = this.el.querySelector("[type='submit']");
        if (!btn) return false;
        if (btn.hasAttribute("href")) {
            btn.removeAttribute("href");
        }
        btn.addEventListener('click', async (event) => {
            await this.submit(event);
        });

        return true;
    }
    /**
     * 
     * @param {*} response 
     * @description This method will run until it is discontinued in previous versions.
     */

    execDefaultPixel(response) {
        if ((response.pingPostResponse && response.pingPostResponse.status === "ok" && response.status === "ok") ||
            (response.status === "ok" && !response.pingPostResponse)) {
            if (typeof this.lgPixels != "undefined" && this.lgPixels.pixels) {
                for (let pixel in this.lgPixels.pixels) {
                    let leadId;
                    if (typeof this.lgCustom != "undefined" && this.lgCustom.leadIdFromCampaignIntegration)
                        leadId = this.lgCustom.leadIdFromCampaignIntegration(response);
                    else
                        leadId = response.leadId;

                    let url = this.lgPixels.pixels[pixel].replace("[leadId]", leadId);

                    let img = document.createElement('img');
                    img.src = url;
                    img.style.display = "none";
                    document.body.appendChild(img);
                    [].length
                }
            }


        }
    }

    createPixel({ url, leadId } = {}) {

        if (!url || !leadId) return false;

        let _url = url.replace("[leadId]", leadId);
        let img = document.createElement('img');
        img.src = _url;
        img.style.display = "none";
        document.body.appendChild(img);

        return true;
    }

    controlType(element) {
        let type = element.getAttribute("data-slg-control-type");
        if (type === "number") return /^-?\d*$/.test(element.value);
        if (type === "text") return /^[A-Za-z\s]+$/g.test(element.value);
        return true;
    }

    controlMaxLength(element) {
        let maxLenght = element.getAttribute("data-slg-control-max-length");
        if (maxLenght && maxLenght != "") return element.value.length <= maxLenght;
        return true;
    }

    setInputFilter(element, filter) {
        ["input", "keydown", "keyup", "change", "mousedown", "mouseup", "select", "contextmenu"].forEach((event) => {
            element.addEventListener(event, (e) => {
                if (filter(element)) {
                    element.oldValue = element.value;
                    element.oldSelectionStart = element.selectionStart;
                    element.oldSelectionEnd = element.selectionEnd;
                    if (e.key === "Backspace" && element.selectionStart === 0) {
                        element.oldValue = "";
                    }
                } else if (element.hasOwnProperty("oldValue") && element.oldValue.length > 1) {
                    if (element.oldValue.length > element.value.length) {
                        element.oldValue = element.value;
                    } else element.value = element.oldValue;
                    // element.setSelectionRange(element.oldSelectionStart, element.oldSelectionEnd);
                } else {
                    // element.value = "";
                }
            });
        });
    }

    initControlInputs() {

        let inputsControl = this.el.querySelectorAll('[data-slg-control-type], [data-slg-control-max-length]');
        for (let i = 0; i < inputsControl.length; i++) {
            let element = inputsControl[i];
            ["input", "keydown", "keyup", "change", "mousedown", "mouseup", "select", "contextmenu"].forEach((event) => {

                element.addEventListener(event, (e) => {
                    if (
                        !(this.controlType(element) && this.controlMaxLength(element)) &&
                        element.hasOwnProperty("oldValue")
                    ) {
                        if (element.oldValue.length < element.value.length)
                            element.value = element.oldValue;
                        // element.setSelectionRange(element.oldSelectionStart, element.oldSelectionEnd);
                    }
                    element.oldValue = element.value;
                    element.oldSelectionStart = element.selectionStart;
                    element.oldSelectionEnd = element.selectionEnd;
                });
            });
        }

    }



}