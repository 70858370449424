import { getAgeFromBirthDate, getFieldsFromEl } from "@/utils/index";
export default class LeadgreaseConditional {
    constructor(el, form) {
        this.conditions = [];
        this.fields = [];
        if (el instanceof HTMLElement) {
            this.el = el;
        }
        else {
            this.el = document.querySelector(el);
        }
        this.form = form;
        this.noHide = el.hasAttribute("data-lg-no-hide");
        this.fields = getFieldsFromEl(this.el);
        this.hide();
        this.initCondition();
    }
    initCondition() {
        let conditions = this.el.getAttribute('data-lg-conditions');
        if (conditions && conditions != "")
            this.conditions = (new Function("return " + conditions + ";")());
    }
    getFields() {
        return this.fields;
    }
    getConditions() {
        return this.conditions;
    }
    show() {
        if (!this.noHide) {
            this.el.style.display = "block";
        }
        this.isRequired(true);
        this.isValidated(true);
    }
    hide() {
        if (!this.noHide) {
            this.el.style.display = "none";
        }
        this.isRequired(false);
        this.isValidated(false);
    }
    validate() {
        let isValid = this.checkCondition();
        if (isValid) {
            this.show();
        }
        else {
            this.hide();
        }
        return isValid;
    }
    isRequired(valid) {
        this.fields.forEach((field) => {
            field.required = valid;
        });
    }
    isValidated(toogle) {
        this.fields.forEach((field) => {
            field.toggleAttribute("data-lg-no-validate", !toogle);
        });
    }
    update() {
    }
    checkCondition() {
        for (let i = 0; i < this.conditions.length; i++) {
            let name = this.conditions[i]['name'];
            let condition = this.conditions[i]['condition'];
            let value = this.conditions[i]['value'];
            let dataForm = this.form.data;
            let dataFormValue = dataForm[name];
            if (name == 'birth_date') {
                dataFormValue = getAgeFromBirthDate(dataFormValue);
            }
            switch (condition) {
                case 'equal':
                    if (!(dataFormValue == value)) {
                        return false;
                    }
                    break;
                case 'not_equal':
                    if (!(dataFormValue != value)) {
                        return false;
                    }
                    break;
                case 'greater_than':
                    if (!(dataFormValue >= value)) {
                        return false;
                    }
                    break;
                case 'less_than':
                    if (!(dataFormValue <= value)) {
                        return false;
                    }
                    break;
                default:
            }
        }
        return true;
    }
}
