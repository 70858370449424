import { isElement } from "underscore";
export class LeadgreaseSelect{

    constructor(el){
        this.el = isElement(el) ? el : document.querySelector(el);
        this.createPlaceholder();
    }

    /**
     * 
     * @param {*} el DOM select element
     * @description Check is option exist in a select element.
     */

     checkIsOptionExist(option){
        let element = this.el.querySelector(`option[value="${option}"]`);
        return (element) ? true : false;
    }

    /**
     * 
     * @description Create an option element by the placeholder of the select element
     *  
     */

    createPlaceholder(){


        let placeholder = this.el.getAttribute('placeholder');
        if(!this.checkIsOptionExist(placeholder) && placeholder){
            let option = document.createElement('option');
            option.setAttribute('hidden','');
            option.setAttribute('value','');
            option.setAttribute('selected','');
            option.innerHTML = placeholder;
            this.el.append(option);
        }
        
    }
}