export function buildFormJson(Config) {
    let formOrigin = document.getElementById(Config.formContainer);
    var arr = formOrigin.elements, i = 0, l = arr.length;
    var fillFields = Config.fillFields, ii = 0, ll = fillFields.length;
    let formHasJson = {
        formKey: Config.formKey
    };
    for (var formFields in formOrigin.elements) {
        for (var field in Config.fillFields) {ò
            if (formOrigin.elements[formFields].name === field)
                formHasJson[Config.fillFields[field]] = formOrigin.elements[formFields].value
        }
    }
    for (var field in Config.customFields) {
        formHasJson[field] = Config.customFields[field];
    }

    return formHasJson;
}

export function autoPopulateQueryToForm(){
    let populateKey = Object.keys(getPopulateQuery())
    if (populateKey.length)
        Object.entries(populateKey).forEach(([key, value]) => {
            if (typeof (document.getElementsByName(value)) !== "undefined" && typeof (document.getElementsByName(value)[0]) !== "undefined")
                document.getElementsByName(value)[0].value = getParameterByName(value);
        });
}

export function stringToHTML (str) {
    var parser = new DOMParser();
    var doc = parser.parseFromString(str, 'text/html');
    console.log(doc.body.firstChild)
    return doc.body.firstChild;
};

export function valueExist (value, element) {
    let options = element.querySelectorAll('option');
    let find = Array.from(options).find((option)=>{
        return value == option.value;
    })

    return find ? true:false;
}

export function isLeapYear(year) {
    year = parseInt(year);
    if (year % 4 != 0) {
        return false;
    } else if (year % 400 == 0) {
        return true;
    } else if (year % 100 == 0) {
        return false;
    } else {
        return true;
    }
}

export async function fetchIP(){
    return fetch("https://api.ipify.org?format=json")
        .then(async function (response) {
            let data = await response.json();
            return data;

        });
}

export function getAgeFromBirthDate(birthDate){
    return Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e+10);
}

export function getFieldsFromEl(el){
    el = (el) ? el : this.el;
    let inputs = Array.from(el.querySelectorAll('input'));
    let selects = Array.from(el.querySelectorAll('select'));
    let fields = inputs.concat(selects);
    return fields;
}