// import { isElement } from "underscore";
export default class Loader {
    constructor(el = '#loader') {
        this.active = false;
        // this.el = isElement(el) ? el : document.querySelector(el);
        if (el instanceof HTMLElement) {
            this.el = el;
        }
        else {
            this.el = document.querySelector(el);
        }
        this.active = true;
    }
    show() {
        if (!this.active) {
            return;
        }
        if (this.el) {
            this.el.style.display = "block";
        }
    }
    hide() {
        if (!this.active) {
            return;
        }
        if (this.el)
            this.el.style.setProperty("display", "none", "important");
    }
    disableLoader() {
        this.active = false;
    }
    activeLoader() {
        this.active = true;
    }
}
