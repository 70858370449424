import getParameterByName from "@/utils/getParameterByName";
import { valueExist } from "@/utils/index";
import { isElement } from "underscore";
import $ from "jquery";
export class LeadgreaseQuestion{

    constructor(el){
        this.el = isElement(el) ? el : document.querySelector(el);
        this.name = "LeadgreaseQuestion";
        this.initCoregistro();
    }

    initCoregistro(){
        // case select
        let select = this.el.querySelector('[data-slg-type="C-SELECT-OPTIONS"]');
        let containerCoregistro = this.el.querySelector('[data-slg-type="C-CONTAINER-COREGISTRO"]');
        if(select){
            this.checkSelectedOption(select,containerCoregistro);
            select.addEventListener('change',(e)=>{
                this.checkSelectedOption(select,containerCoregistro);
            });
        }
        // case radio
        let radio = this.el.querySelector('[data-slg-type="C-RADIO-GROUP"]');
        if(radio && containerCoregistro){
            let options = radio.querySelectorAll('input[type="radio"]');
            for (let i = 0; i < options.length; i++) {
                const option = options[i];
                let coregistros = option.getAttribute('data-slg-coregistros');
                coregistros = JSON.parse(coregistros);

                let coregistros_inputs = document.querySelectorAll('input');


                if(coregistros.length > 0){
                    option.addEventListener('click',()=>{
                        containerCoregistro.style.display = "block";
                        Array.from(coregistros_inputs).map((input)=>{
                            input.setAttribute('required','');
                        });
                    });
                }else{
                    option.addEventListener('click',()=>{
                        containerCoregistro.style.display = "none";
                        Array.from(coregistros_inputs).map((input)=>{
                            input.removeAttribute('required','');
                        });
                    });
                }
            }
        }
    }

    getData(){

        let unindexed_array = $("form").serializeArray();
        var indexed_array = [];
        $.map(unindexed_array, function(n, i){ // $ eliminar Debera ser usado con JS Vainilla
            /***
             * Evaluar si es un coreg.
             */
    
            let questionMatch = n['name'].match(/(answers)\[(.*)]/);
    
            if(questionMatch){
    
                let QuestionId = questionMatch[2];
                if(n['value'] && n['value'] != ''){
                    if(document.getElementsByName(n['name'])[0] &&
                        document.getElementsByName(n['name'])[0].getAttribute("data-slg-coregistros") &&
                        JSON.parse(document.getElementsByName(n['name'])[0].getAttribute("data-slg-coregistros")).length > 0)
                    {
                        let textValue=document
                                .querySelector("[data-slg-question-id='" +  QuestionId + "']")
                                .querySelector("[data-slg-type='C-CONTAINER-COREGISTRO'] input")
                                .value;
                        indexed_array.push({
                            "question_id": QuestionId,
                            "option_id":  n['value'],
                            "text": textValue
                        });
                            
                    }
                    else {
                        indexed_array.push({
                            "question_id": QuestionId,
                            "option_id":  n['value'],
                        });
                    }
    
                }
    
            }
    
            // indexed_array[n['name']] = n['value'];
        });
    
        return indexed_array;
    }

    checkSelectedOption(selectOptions,containerCoregistro){
        let option = selectOptions.querySelector(`option[value="${selectOptions.value}"]`);
        let coregistros = option.getAttribute('data-slg-coregistros');
        coregistros = JSON.parse(coregistros);
        if(coregistros && coregistros.length > 0 && containerCoregistro){
            containerCoregistro.style.display = "block";
        }else if(containerCoregistro){
            containerCoregistro.style.display = "none";
        }
    }

}